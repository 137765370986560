import { CRYPTO_SECRET_KEY, STATUS_LIST } from "config/config";
import CryptoJS from "crypto-js";
import { SERVER_FILE_PATH } from "config/config";
import { BRAND_LIST } from "utils/DataHelper";
import { GOSHEN_ARMS_LIST } from "config/config";
import { PERMISSION_LIST } from "config/config";
import { AGE } from "config/config";
import { RACE } from "config/config";
import { RELIGION } from "config/config";
import { BACKGROUND } from "config/config";
import { OUTCOME } from "config/config";
import { ROLE } from "config/config";

export const routeScrollToTop = () => {
  // called on route change
  window.$("html, body").animate({ scrollTop: 0 }, "300");
};
export const generateRandomNumber = () => parseInt(Date.now() * Math.random());
export const decryptObject = (obj) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(obj, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8)
  );
};
export const encryptObject = (obj) => {
  obj = JSON.stringify(obj);
  return CryptoJS.AES.encrypt(obj, CRYPTO_SECRET_KEY).toString();
};
export const capitalizeWord = (text) => {
  const words = text.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
};
export const checkActiveRoute = (
  activeRoute,
  routeList,
  haveChild = false,
  matchExactRoute = false
) => {
  var className = "";
  if (!matchExactRoute) {
    const paths = activeRoute.split("/");
    className =
      paths.some((path) => routeList.includes(path)) ||
      activeRoute.includes(routeList[0])
        ? "active"
        : "";
  } else {
    className = routeList.every((e) => e.includes(activeRoute)) ? "active" : "";
  }
  if (haveChild && className == "active") {
    className += " show";
  }
  return className;
};
export const numberFormatShort = (numberVal, precision = 0) => {
  let n_format = "";
  let suffix = "T";
  if (numberVal < 999) {
    // 0 - 999
    n_format = numberVal.toFixed(precision);
    suffix = "";
  } else if (numberVal < 900000) {
    // 0.9k-850k
    n_format = (numberVal / 1000).toFixed(precision);
    suffix = "K";
  } else if (numberVal < 900000000) {
    // 0.9m-850m
    n_format = (numberVal / 1000000).toFixed(precision);
    suffix = "M";
  } else if (numberVal < 900000000000) {
    // 0.9b-850b
    n_format = (numberVal / 1000000000).toFixed(precision);
    suffix = "B";
  } else {
    // 0.9t+
    n_format = (numberVal / 1000000000000).toFixed(precision);
    suffix = "T";
  }
  return n_format + suffix;
};
export const encryptText = (message) => {
  message = message.toString();
  const cipherText = CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY);
  return encodeURIComponent(cipherText.toString());
};
export const decryptText = (message) => {
  var bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(message),
    CRYPTO_SECRET_KEY
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};
export const placeParams = (pathRegex, params) => {
  const segments = pathRegex.split("/");
  return (
    segments
      .map((segment) => {
        let offset = segment.indexOf(":?") + 1;

        if (offset) {
          let key = segment.slice(offset + 1);
          return params[key];
        }

        offset = segment.indexOf(":") + 1;
        if (!offset) return segment;

        let key = segment.slice(offset);
        return params[key];
      })
      .join("/")
      // Remove trailing "/"
      .replace(/\/+$/, "")
      // Remove redundant "/"
      .replace(/\/{2,}/, "/")
  );
};
export const getImageUrl = (type, path) => {
  let dir = SERVER_FILE_PATH;
  switch (type) {
    case "profile":
      dir += "/image_library/profile_images/";
      break;
    case "doc":
      dir += "/image_library/document/";
      break;
  }

  return dir + path;
};
export const renderStr = (parameter) => {
  if (
    parameter == null ||
    parameter == "" ||
    parameter == "0000-00-00 00:00:00"
  ) {
    return "-";
  }
  return parameter;
};
export const calculateRewardPercentage = (
  totalScan,
  totalReward,
  perfix = 2
) => {
  // Ensure totalScan and totalReward are valid numbers
  if (
    typeof totalScan !== "number" ||
    typeof totalReward !== "number" ||
    totalScan < 0 ||
    totalReward < 0
  ) {
    throw new Error(
      "Invalid input. Both totalScan and totalReward must be non-negative numbers."
    );
  }
  if (totalScan === 0) {
    return 0;
  } else {
    //return (totalReward / totalScan) * 100;
    return Number(((totalReward / totalScan) * 100).toFixed(perfix));
  }
};
export const getBrandTypeObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = BRAND_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getGoshenArmsObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = GOSHEN_ARMS_LIST.filter(function (item) {
    return item.slug == slug;
  });

  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }

  return selectedSlug;
};
export const getAgeObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = AGE.filter(function (item) {
    return item.name == slug;
  });

  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }

  return selectedSlug;
};
export const getraceObjBySlug = (slug) => {
  var selectedSlug = null;

  const filteredData = RACE.filter(function (item) {
    return item.slug == slug;
  });

  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getRoleSlugObjBySlug = (slug) => {
  var selectedSlug = null;

  const filteredData = ROLE.filter(function (item) {
    return item.slug == slug;
  });

  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};

export const getoutComeObjBySlug = (slug, keyname = "name") => {
  var selectedSlug = null;

  const filteredData = OUTCOME.filter(function (item) {
    return item[keyname] == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// export const getoutComeObjBySlug = (slug) => {
//   var selectedSlug = null;
//   const filteredData = OUTCOME.filter(function (item) {
//     return item.slug == slug;
//   });

//   if (filteredData.length > 0) {
//     selectedSlug = filteredData[0];
//   }

//   return selectedSlug;
// };
export const getReligionObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = RELIGION.filter(function (item) {
    return item.name == slug;
  });

  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }

  return selectedSlug;
};
export const getBackgroundObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = BACKGROUND.filter(function (item) {
    return item.name == slug;
  });

  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }

  return selectedSlug;
};
export const getPermitionObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = PERMISSION_LIST.filter(function (item) {
    return item.slug == slug;
  });

  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }

  return selectedSlug;
};

export const customStyles = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00254F" : "#00254F", // Control background
      padding: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff", // Selected value colore
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#00254F", // Background color of selected items
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#fff", // Color of the close icon
      backgroundColor: state.isFocused ? "#555" : "#444", // Background color of the close icon
      ":hover": {
        backgroundColor: "#666", // Background color of the close icon on hover
        color: "#fff", // Color of the close icon on hover
      },
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#444", // Text color of selected items
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc", // Placeholder text color
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#003978", // Dropdown menu background color
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff", // Text color of searchable value
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "grey" : "#003978", // Option background color
      color: "#fff", // Option text color
    }),
  };
};
export const customStylesBridger = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "#fff", // Control background
      padding: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "black", // Selected value colore
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#00254F", // Background color of selected items
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#fff", // Color of the close icon
      backgroundColor: state.isFocused ? "#555" : "#444", // Background color of the close icon
      ":hover": {
        backgroundColor: "#666", // Background color of the close icon on hover
        color: "#fff", // Color of the close icon on hover
      },
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#444", // Text color of selected items
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#ccc", // Placeholder text color
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#003978", // Dropdown menu background color
    }),
    // input: (provided) => ({
    //   ...provided,
    //   color: "#fff", // Text color of searchable value
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "grey" : "#003978", // Option background color
      color: "#fff", // Option text color
    }),
  };
};
export const isBlobOrObjectUrl = (url) => {
  const blobObjectUrlRegex =
    /^blob:(?<origin>[\w\+]+:\/\/(?=.{1,254}(?::|$))(?:(?!\d|-)(?![a-z0-9\-]{1,62}-(?:\.|:|$))[a-z0-9\-]{1,63}\b(?!\.$)\.?)+(:\d+)?)\/(?<uuid>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;
  return typeof url === "string" && blobObjectUrlRegex.test(url);
};

export const formatTwoDigits = (number) => {
  return number.toString().padStart(2, '0');
}